import React from "react";

function About() {
  return (
    <div id="About">
      <div className="breadcrumb">
        <i className="fa-solid fa-house"></i>
        {" > About Us > About"}{" "}
      </div>
      <div>
        <h1>
          Legal Venture Name: Psycortex Brain Take Mental Health (OPC) Pvt. Ltd
        </h1>
        <p>
          Welcome to Psycortex Brain Take Mental Health (OPC) Pvt. Ltd, where we
          specialize in providing comprehensive and transformative mental health
          counseling and services. Situated at the forefront of mental wellness,
          our clinic is a sanctuary of compassion and expertise, dedicated to
          nurturing the health and happiness of our clients.
        </p>
        <p>
          At Psycortex Brain Take Mental Health (OPC) Pvt. Ltd, we recognize the
          intricate interplay between mind, body, and spirit, and our team of
          experienced psychologists, therapists, and counselors are committed to
          offering personalized and effective treatment plans tailored to your
          unique needs. Whether you're grappling with the weight of anxiety,
          depression, trauma, or facing challenges in relationships and personal
          growth, we are here to guide you towards profound healing and
          self-discovery.
        </p>
        <p>
          Our approach encompasses a diverse range of therapeutic modalities,
          including cognitive behavioral therapy (CBT), mindfulness-based
          practices, psychodynamic therapy, and more. We believe in the power of
          evidence-based interventions to empower individuals and foster lasting
          change.
        </p>
        <p>
          Step into our nurturing environment, designed to cultivate trust,
          empathy, and confidentiality. Here, you'll find a supportive space to
          explore your emotions and experiences without judgment. Your mental
          well-being is our top priority, and we are honored to accompany you on
          your journey towards resilience and fulfillment.
        </p>
        <p>
          Take the courageous step towards a brighter tomorrow—contact Psycortex
          Brain Take Mental Health (OPC) Pvt. Ltd today, and embark on a
          transformative path towards greater emotional well-being and a more
          meaningful life. Together, let's unlock the potential for growth and
          healing within you.
        </p>
      </div>
    </div>
  );
}

export default About;
