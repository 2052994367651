import React from "react";

function LoadingBar() {
  return (
    <div id="LoadingBar">
      <div class="loading-wave">
        <div class="loading-bar"></div>
        <div class="loading-bar"></div>
        <div class="loading-bar"></div>
        <div class="loading-bar"></div>
      </div>
    </div>
  );
}

export default LoadingBar;
